<template>
  <div class="w-2/3 relative left-1/2 transform -translate-x-1/2">
    <lottie-animation
      path="lotties/maintenance.json"
      :loop="true"
      :autoPlay="true"
      :speed="1"
      :height="640"
    />
    <p class="text-3xl text-center">
      Sorry, we're down for a scheduled maintenance. Please check back in
      sometime.
    </p>
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  name: "Maintenance",
  components: { LottieAnimation },
  mounted() {
    this.$store
      .dispatch("getProfile")
      .then((response) => {
        if (response.data && !response.data.errorcode) {
          this.$router.push({
            name: "UserHome",
            params: { company: this.$store.getters.companySlug },
          });
        } else {
          return;
        }
      })
      .catch(() => {});
  },
};
</script>

<style scoped></style>
